
















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class Headline extends Vue {
  @Prop()
  title: any;

  @Prop()
  subtitle: any;

  public mounted() {}
}
