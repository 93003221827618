


















































import Vue from "vue";
import { Component } from "vue-property-decorator";
import LoginDialog from "../User/LoginDialog.vue";
import ContactDialog from "./ContactDialog.vue";

@Component({
  components: { LoginDialog, ContactDialog },
})
export default class Footer extends Vue {
  public instaLink: string = "https://www.instagram.com/bv57niedersedlitz/";
  public showLoginDialog = false;
  public showContacts = false;
  public mounted() {}
  public openLoginDialog() {
    this.showLoginDialog = true;
  }
  public openContactDialog() {
    this.showContacts = true;
  }
}
