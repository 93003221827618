import Vue from 'vue';
import Vuex from 'vuex'
import { i18n } from '../i18n';


Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
        accessToken: localStorage.getItem('accessToken') || '',
        language: localStorage.getItem('lang') || 'de',
        isMobile: true,
        isWide: true,
    },
    mutations: {
        clearSession(state: any) {
            state.accessToken = '';
            localStorage.removeItem('accessToken');
        },
        updateAccessToken(state: any, token: string) {
            state.accessToken = token;
        },
        updateLang(state: any, lang: string) {
            state.language = lang;
            i18n.locale = lang;
            localStorage.setItem('lang', lang);
        },
        setMobile(state: any, isMobile: boolean) {
            state.isMobile = isMobile;
        },
        setWide(state: any, isWide: boolean) {
            state.isWide = isWide;
        }
    },
    getters: {
        isLoggedIn: state => !!state.accessToken,
        accessToken: (state: any) => {
            return state.accessToken;
        },
        isMobile: (state: any) => {
            return state.isMobile;
        },
        isWide: (state: any) => {
            return state.isWide;
        }
    },
    actions: {},
})
