













import Vue from "vue";
import { Component } from "vue-property-decorator";
import isMobile from "mobile-device-detect";
import MobileLanding from "./MobileLanding.vue";
import DesktopLanding from "./DesktopLanding.vue";

@Component({
  components: {
    MobileLanding,
    DesktopLanding,
  },
})
export default class Landing extends Vue {
  public isMobileDevice = isMobile.isMobileOnly;
  public mounted() {
    setInterval(() => {
      this.isMobileDevice = isMobile.isMobileOnly;
    }, 5000);
  }
}
