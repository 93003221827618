


































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class Team extends Vue {
  @Prop()
  title: any;
  @Prop()
  subtitle: any;
  @Prop()
  link: any;
  @Prop()
  teamShortcut: any;

  @Prop()
  players: any;
}
