import Vue from 'vue';
import App from './App.vue';
import { store } from "./plugins/vuex/store";
import vuetify from './plugins/vuetify';
import router from "./router/router";
import { i18n } from './plugins/i18n';
import VueMeta from 'vue-meta'
import './base.scss';

Vue.config.productionTip = false
Vue.use(VueMeta);

new Vue({
  vuetify,
  router,
  store: store,
  i18n,
  render: h => h(App)
}).$mount('#app')
