





























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import DatabaseService from "../../../services/databaseService";
import ResultsTable from "../../util/ResultsTable.vue";
import AddResult from "../../User/AddResult.vue";

@Component({
  components: {
    ResultsTable,
    AddResult,
  },
})
export default class LatestResults extends Vue {
  @Prop()
  public cardWidth: string;

  @Prop()
  public cardHeight: number;
  public results: any = [];
  public dbService = new DatabaseService();
  public showAddDialog = false;

  public async mounted() {
    this.getResults();
  }

  public async getResults() {
    this.results = await this.dbService.getLatestResults("&_limit=30");
  }

  public async closeDialog() {
    await this.getResults();
    this.showAddDialog = false;
  }

  /** computed property that determines logged in status */
  get loggedIn() {
    return this.$store.getters.isLoggedIn;
  }
}
