




































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { mdiAccount } from "@mdi/js";

@Component
export default class Contact extends Vue {
  public userIcon: any = mdiAccount;
}
