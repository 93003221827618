











































import DatabaseService from "@/services/databaseService";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import EditAlertCard from "@/components/User/EditAlertCard.vue";

@Component({
  components: {
    EditAlertCard,
  },
})
export default class AlertCard extends Vue {
  @Prop()
  public cardWidth: string;

  private dbService = new DatabaseService();

  public cardColor: string = "";
  public cardTitle: string = "";
  public cardText: string = "";
  public active: boolean = true;
  public whiteFont: boolean = false;

  public showEditDialog = false;

  /** computed property that determines logged in status */
  get loggedIn() {
    return this.$store.getters.isLoggedIn;
  }

  public mounted() {
    this.updateCard();
  }

  public updateCard() {
    this.dbService.getAlertCard().then((result: any) => {
      this.active = result.active;
      this.cardTitle = result.cardTitle;
      this.cardText = result.cardText;
      this.cardColor = result.cardColor;
      this.calculateContrast();
    });
  }

  public calculateContrast() {
    let rgbColor = this.hex2Rgb(this.cardColor);
    if (rgbColor) {
      let blackContrast = this.contrast(
        [rgbColor.r, rgbColor.g, rgbColor.b],
        [0, 0, 0]
      );
      let whiteContrast = this.contrast(
        [rgbColor.r, rgbColor.g, rgbColor.b],
        [255, 255, 255]
      );
      if (whiteContrast > blackContrast) {
        this.whiteFont = true;
      }
    }
  }

  public luminanace(r: any, g: any, b: any) {
    var a = [r, g, b].map(function (v) {
      v /= 255;
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4);
    });
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
  }
  public contrast(rgb1: any, rgb2: any) {
    var lum1 = this.luminanace(rgb1[0], rgb1[1], rgb1[2]);
    var lum2 = this.luminanace(rgb2[0], rgb2[1], rgb2[2]);
    var brightest = Math.max(lum1, lum2);
    var darkest = Math.min(lum1, lum2);
    return (brightest + 0.05) / (darkest + 0.05);
  }

  /**
   * Convert HEX color to RGB
   */
  public hex2Rgb = function (hex: any) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  };

  public closeDialog() {
    this.showEditDialog = false;
    this.updateCard();
  }
}
