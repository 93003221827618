








































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { DownloadService } from "../../service/downLoadService";
import AllDownloads from "../util/AllDownloads.vue";
const Antrag_Mitgliedschaft = require("../../assets/documents/Antrag_Mitgliedschaft.pdf");
const Sepa_Mandat = require("../../assets/documents/sepa_mandat.pdf");
const ordnung_satzung = require("../../assets/documents/ORDNUNG-Satzung.pdf");

@Component({
  components: {
    AllDownloads,
  },
})
export default class Downloads extends Vue {
  public downloadService: any = new DownloadService();
  public showAllDownloads = false;

  public links: any = [
    {
      text: "downloads.request",
      icon: "mdi-download",
      name: Antrag_Mitgliedschaft.default,
      alias: "Mitgliedsantrag stellen",
    },
    {
      text: "downloads.sepa",
      icon: "mdi-download",
      name: Sepa_Mandat.default,
      alias: "Sepa Lastchriftmandat",
    },
    {
      text: "downloads.satzung",
      icon: "mdi-download",
      name: ordnung_satzung.default,
      alias: "Satzung",
    },
    {
      text: "downloads.bvs",
      icon: "mdi-arrow-right",
      name: "link",
      alias: "",
    },
  ];

  /*
   * navigating to the webiste of BVS
   */
  public navigateToBvs() {
    let link = "https://www.bvsachsen.de/";
    window.location.href = link;
  }

  public download(filename: any, alias: any) {
    this.downloadService.downloadFile(filename, alias);
  }

  public closeDialog() {
    this.showAllDownloads = false;
  }
}
