











































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Headline from "../util/Headline.vue";
import Skeleton from "../util/Skeleton.vue";
import { MetaInfo } from "vue-meta/types/vue-meta";

@Component({
  metaInfo(): MetaInfo {
    return {
      title: "Über uns",
      meta: [
        { charset: "utf-8" },
        {
          name: "keyword",
          content:
            "badminton, dresden, sport, website, club, Badminton Dresden, Badmintonverein, Badminton Verein, Federball, Federball Dresden, Smash",
        },
        { name: "viewport", content: "initial-scale=1.0;" },
        { name: "icon", content: "./favicon.png" },
      ],
    };
  },
  components: {
    Headline,
    Skeleton,
  },
})
export default class About extends Vue {
  public loaded = false;
  public mounted() {
    this.$nextTick(function () {
      //this.loaded = true;
    });
  }
}
