











































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import DatabaseService from "../../services/databaseService";
import SnackBar from "../util/SnackBar.vue";
import Rule from "../util/rules";

// TODO: fix snackbar not displaying

@Component({
  components: {
    SnackBar,
  },
})
export default class EditAusfall extends Vue {
  @Prop()
  ausfallEntry: any;

  public requiredRule = new Rule().requiredRule;

  public valid = false;

  public reason: string = "";
  public date: string = "";
  public partly: string = "";

  public dbService = new DatabaseService();
  public showSnackBar = false;

  public mounted() {
    this.reason = this.ausfallEntry.reason;
    this.date = this.ausfallEntry.date;
    this.partly = this.ausfallEntry.partly;
  }

  public closeDialog() {
    this.$emit("closeOverlay");
  }

  public async patchAusfall() {
    let newEntry = {
      date: this.date,
      reason: this.reason,
      partly: this.partly,
    };
    this.dbService
      .patchAusfallEntry(this.ausfallEntry.id, newEntry)
      .then((result: any) => {
        this.closeDialog();
      })
      .catch((err: any) => {
        alert(err);
      });
  }
}
