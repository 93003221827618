






















import Vue from "vue";
import { Component } from "vue-property-decorator";
import Ausfall from "../Ausfall/Ausfall.vue";
import Headline from "../util/Headline.vue";
import JoinUs from "./detail/JoinUs.vue";
import Links from "./detail/Links.vue";
import LatestResults from "./detail/LatestResults.vue";
import AlertCard from "@/components/Landing/AlertCard.vue";

const Antrag_Mitgliedschaft = require("../../assets/documents/Antrag_Mitgliedschaft.pdf");
const Ruhende_Mitgliedschaft = require("../../assets/documents/ruhende_mitgliedschaft.pdf");
const Sepa_Mandat = require("../../assets/documents/sepa_mandat.pdf");

@Component({
  components: {
    Ausfall,
    Headline,
    JoinUs,
    Links,
    LatestResults,
    AlertCard,
  },
})
export default class MobileLanding extends Vue {
  public hoverId: any = null;
  public cardSize: string = "430px"; // window.screen.height / 2;

  public antragFile: any = Antrag_Mitgliedschaft.default;
  public ruhendeMitgliedschaft: any = Ruhende_Mitgliedschaft.default;
  public sepaMandat: any = Sepa_Mandat.default;

  /*
   * navigating to the webiste of BVS
   */
  public navigateTo(link: string) {
    window.location.href = link;
  }
}
