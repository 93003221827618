






import Navigation from "./components/Navigation/Navigation.vue";
import Vue from "vue";
import { Component } from "vue-property-decorator";
import DatabaseService from "./services/databaseService";
import { MetaInfo } from "vue-meta/types/vue-meta";

@Component({
  metaInfo(): MetaInfo {
    return {
      title: "BV57-Niedersedlitz",
      meta: [
        { charset: "utf-8" },
        {
          name: "keyword",
          content:
            "badminton, dresden, sport, website, club, Badminton Dresden, Badmintonverein, Badminton Verein, Federball, Federball Dresden, Smash",
        },
        { name: "viewport", content: "initial-scale=1.0;" },
        { name: "icon", content: "./favicon.png" },
        {
          name: "description",
          content:
            "BV57 Niedersedlitz ist ein Badmintonverein in Dresden. Schaut einfach rein, wenn ihr Lust habt.",
        },
        { name: "author", content: "Kristof Kind" },
        // Twitter Card
        { name: "twitter:card", content: "BV57 Niedersedlitz" },
        { name: "twitter:title", content: "BV57 Niedersedlitz" },
        {
          name: "twitter:description",
          content: "BV57 Niedersedlitz - Badmintonverein in Dresden",
        },
        // image must be an absolute path
        { name: "twitter:image", content: "./favicon.png" },
        // Facebook OpenGraph
        { property: "og:title", content: "BV57 Niedersedlitz" },
        { property: "og:site_name", content: "BV57 Niedersedlitz" },
        { property: "og:type", content: "website" },
        { property: "og:image", content: "./favicon.png" },
        {
          property: "og:description",
          content: "BV57 Niedersedlitz - Badmintonverein in Dresden",
        },
      ],
    };
  },
  components: {
    Navigation,
  },
})
export default class App extends Vue {
  private dbService = new DatabaseService();
  public mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
    this.dbService
      .testLogin()
      .then((result: any) => {})
      .catch((err: any) => {
        this.$store.commit("clearSession");
      });
  }

  public onResize() {
    if (window.innerWidth < 769) this.$store.commit("setMobile", true);
    else this.$store.commit("setMobile", false);

    if (window.innerWidth > 1300) this.$store.commit("setWide", true);
    else this.$store.commit("setWide", false);
  }
}
