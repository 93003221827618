











































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import DatabaseService from "../../services/databaseService";
import SnackBar from "../util/SnackBar.vue";
import Rule from "../util/rules";

// TODO: fix snackbar not displaying

@Component({
  components: {
    SnackBar,
  },
})
export default class EditAlertCard extends Vue {
  @Prop()
  alertCard: any;

  public requiredRule = new Rule().requiredRule;

  public valid = false;

  public active: boolean = false;
  public cardTitle: string = "";
  public cardText: string = "";
  public cardColor: string = "";

  public dbService = new DatabaseService();
  public showSnackBar = false;

  public mounted() {
    this.active = this.alertCard.active;
    this.cardTitle = this.alertCard.cardTitle;
    this.cardText = this.alertCard.cardText;
    this.cardColor = this.alertCard.cardColor;
  }

  public closeDialog() {
    this.$emit("closeOverlay");
  }

  public async setCard() {
    let updatedCard = {
      active: this.active,
      cardTitle: this.cardTitle,
      cardText: this.cardText,
      cardColor: this.cardColor,
    };
    this.dbService
      .updateAlertCard(updatedCard)
      .then((result: any) => {
        this.closeDialog();
      })
      .catch((err: any) => {
        alert(err);
      });
  }
}
