






















































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({})
export default class JoinUs extends Vue {
  @Prop()
  public cardWidth: string;

  @Prop()
  public cardHeight: number;
}
