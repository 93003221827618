











































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import DatabaseService from "../../services/databaseService";
import SnackBar from "../util/SnackBar.vue";
import Rule from "../util/rules";

@Component({
  components: {
    SnackBar,
  },
})
export default class SetAusfallDialog extends Vue {
  @Prop()
  existingAusfall: any[];

  public requiredRule = new Rule().requiredRule;

  public valid = false;

  public reason: string = "";
  public date: string = "";
  public partly: string = "";

  public dbService = new DatabaseService();
  public showSnackBar = false;

  public closeDialog() {
    this.$emit("closeOverlay");
  }

  public async setAusfall() {
    let newEntry = {
      date: this.date,
      reason: this.reason,
      partly: this.partly,
    };
    await this.checkEntries();
    this.dbService
      .addAusfallEntry(newEntry)
      .then((result: any) => {
        this.closeDialog();
      })
      .catch((err: any) => {
        alert(err);
      });
  }

  public async checkEntries() {
    let length = this.existingAusfall.length;
    if (length < 10) {
      return;
    } else {
      await this.dbService.deleteAusfallEntry(
        this.existingAusfall[length - 1].id
      );
      this.existingAusfall.splice(length - 1, 1);
      await this.checkEntries();
      return;
    }
  }
}
