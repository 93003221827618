





































































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import DatabaseService from "../../services/databaseService";
import SetAusfallDialog from "../User/SetAusfallDialog.vue";
import EditAusfall from "../User/EditAusfall.vue";
import DeleteAusfall from "../User/DeleteAusfall.vue";

@Component({
  components: {
    SetAusfallDialog,
    EditAusfall,
    DeleteAusfall
  },
})
export default class Ausfall extends Vue {
  @Prop()
  public cardWidth: string;

  @Prop()
  public cardHeight: number;

  public entries: any = [];
  public dbService = new DatabaseService();
  public showAddDialog = false;
  public showEditDialog = false;
  public showDeleteDialog = false;
  public currentEntry: any = "";

  public mounted() {
    this.getAusfallEntries();
  }

  /** computed property that determines logged in status */
  get loggedIn() {
    return this.$store.getters.isLoggedIn;
  }

  public getAusfallEntries() {
    this.dbService
      .getAusfallEntries()
      .then((result: any) => {
        this.entries = result;
      })
      .catch((err: any) => {
        console.log("err: ", err);
      });
  }

  public closeDialog() {
    this.getAusfallEntries();
    this.showAddDialog = false;
    this.showEditDialog = false;
    this.showDeleteDialog = false;
  }

  public showEditAusfallDialog(entry: any) {
    this.currentEntry = entry;
    this.showEditDialog = true;
  }

  public showDeleteAusfallDialog(entry: any) {
    this.currentEntry = entry;
    this.showDeleteDialog = true;
  }
}
