






















































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import ShowAllResults from "./ShowAllResults.vue";
import EditResult from "../User/EditResult.vue";
@Component({
  components: {
    ShowAllResults,
    EditResult,
  },
})
export default class ResultsTable extends Vue {
  @Prop()
  public results: any;
  public currentPage: number = 1;
  public pageCount: number = 0;
  public itemsPerPage: number = 5;
  public itemsPerPageMobile: number = 4;

  public headers: any[] = [];
  public showEditDialog = false;
  public showAllResults = false;
  public currentResult: any = "";

  /** computed property that determines isMobile in status */
  get isMobile() {
    return this.$store.getters.isMobile;
  }

  public async mounted() {
    this.headers = [
      {
        text: this.$t("results.won"),
        value: "won",
        sortable: false,
        filterable: false,
        groupable: false,
        align: "center",
      },
      {
        text: this.$t("results.homeTeam"),
        value: "home",
        sortable: false,
        filterable: false,
        groupable: false,
        align: "center",
      },
      {
        text: "",
        value: "result",
        sortable: false,
        filterable: false,
        groupable: false,
        align: "center",
      },
      {
        text: this.$t("results.awayTeam"),
        value: "away",
        sortable: false,
        filterable: false,
        groupable: false,
        align: "center",
      },
    ];
    this.setPageCount();
    this.setIconColors();
  }

  @Watch("results")
  public resultsChange() {
    this.setIconColors();
  }

  public setIconColors() {
    this.results.forEach((element: any) => {
      switch (element.state) {
        case "win":
          element.color = "success";
          break;
        case "lose":
          element.color = "error";
          break;
        case "draw":
          element.color = "warning";
          break;
        default:
          element.color = "black";
          break;
      }
    });
  }

  public getActiveClass(state: any) {
    let activeClass = "";
    switch (state) {
      case "win":
        activeClass = "won-class";
        break;
      case "lose":
        activeClass = "lost-class";
        break;
      case "draw":
        activeClass = "draw-class";
        break;
    }
    return activeClass;
  }

  public setPageCount() {
    this.pageCount = Math.ceil(this.results.length / this.itemsPerPage);
  }

  /** computed property that determines logged in status */
  get loggedIn() {
    return this.$store.getters.isLoggedIn;
  }

  public closeDialog() {
    this.$emit("getResults");
    this.showEditDialog = false;
    this.showAllResults = false;
  }

  /*
   * navigating to external website
   */
  public navigateTo(link: string) {
    window.location.href = link;
  }

  public showEditAusfallDialog(entry: any) {
    this.currentResult = entry;
    this.showEditDialog = true;
  }
}
