import http from './axiosConfig';
import { store } from '../plugins/vuex/store';

export default class DatabaseService {
    private readonly loginRoute = '/login';
    /**
     * login the user
     */
    public login(username: string, password: string): Promise<any> {
        const body = {
            email: username,
            password: password,
        };
        return http.post(this.loginRoute, body);
    }

    public logout() {
        this.clearSession();
    }

    public clearSession() {
        store.commit('clearSession');
    }

    /** Ausfall route */
    public getAusfallEntries(): Promise<any> {
        return http.get('/ausfall?_sort=id&_order=desc');
    }

    public addAusfallEntry(entry: any): Promise<any> {
        return http.post('/ausfall', entry);
    }

    public deleteAusfallEntry(id: number): Promise<any> {
        return http.delete(`/ausfall/${id}`);
    }

    public patchAusfallEntry(id: number, entry: any): Promise<any> {
        return http.patch(`/ausfall/${id}`, entry);
    }

    /** Latest Results routes, Limited to the latest 20! */
    public getLatestResults(limit?: string): Promise<any> {
        return http.get('/latestResults?_sort=id&_order=desc' + limit);
    }

    public addLatestResult(result: any): Promise<any> {
        return http.post('/latestResults', result);
    }

    public deleteLatestResult(id: number): Promise<any> {
        return http.delete(`/latestResults/${id}`);
    }

    public patchLatestResult(id: number, result: any): Promise<any> {
        return http.patch(`/latestResults/${id}`, result);
    }

    /** Tests if user token is still valid */
    public testLogin(): Promise<any> {
        return http.get('/loggedIn');
    }

    /** get Team by name */
    public getTeam(team: string): Promise<any> {
        return http.get(`/${team}`);
    }

    public getAlertCard() {
        return http.get('/alertCard');
    }

    public updateAlertCard(newCard: any) {
        return http.post('/alertCard', newCard);
    }

    public deleteAusfall(id: number): Promise<any> {
        return http.delete(`/ausfall/${id}`);
    }
}