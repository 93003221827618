







































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Ausfall from "../Ausfall/Ausfall.vue";
import Headline from "../util/Headline.vue";
import JoinUs from "./detail/JoinUs.vue";
import Links from "./detail/Links.vue";
import LatestResults from "./detail/LatestResults.vue";
import AlertCard from "@/components/Landing/AlertCard.vue";

@Component({
  components: {
    Ausfall,
    Headline,
    JoinUs,
    Links,
    LatestResults,
    AlertCard,
  },
})
export default class DesktopLanding extends Vue {
  public hoverId: any = null;

  // used to be calculated dynamically
  /**
   * Height of the landing cards
   */
  public cardSize: any = 450;
  public decreasingOpacity: boolean = true;

  /** computed property that determines isMobile in status */
  get isWide() {
    return this.$store.getters.isWide;
  }

  public handleScroll() {
    let animationElement1 = this.$refs[
      "backgroundImageAnimation"
    ] as HTMLElement;
    let animationElement2 = this.$refs[
      "backgroundImageAnimation2"
    ] as HTMLElement;

    let opacityOfFirstImage = Number(animationElement1.style.opacity);
    let opacityOfSecondImage = Number(animationElement2.style.opacity);

    let result = this.changeOpacityOnScroll(
      opacityOfFirstImage,
      opacityOfSecondImage
    );
    let firstAnimation = this.$refs["backgroundImageAnimation"] as HTMLElement;
    firstAnimation.style.opacity = result.firstImageOpacity.toString();
    let secondAnimation = this.$refs[
      "backgroundImageAnimation2"
    ] as HTMLElement;
    secondAnimation.style.opacity = result.secondImageOpacity.toString();
  }
  public changeOpacityOnScroll(opacity1: number, opacity2: number) {
    if (!opacity2 && !opacity1) {
      opacity2 = 1;
      opacity1 = 0;
    }
    opacity2 = this.handleOpacity(opacity2);
    opacity1 = 1 - opacity2;
    return {
      firstImageOpacity: opacity1,
      secondImageOpacity: opacity2,
    };
  }
  public handleOpacity(opacity: any) {
    if (this.decreasingOpacity === true) {
      opacity = opacity - 0.02;
      if (opacity <= 0) {
        // change direction
        this.decreasingOpacity = false;
      }
    }
    if (this.decreasingOpacity === false) {
      // increasing opcity
      let opacityAsNumber = parseFloat(opacity);
      opacity = opacityAsNumber + 0.02;
      if (opacity >= 1) {
        // change direction
        this.decreasingOpacity = true;
      }
    }
    return opacity;
  }

  public mounted() {
    // init scroll event listener
    window.addEventListener("scroll", this.handleScroll);
  }
  public destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
}
