var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"width":_vm.cardWidth,"flat":"","color":"transparent"}},[(_vm.loggedIn)?_c('v-card-title',{class:_vm.active ? 'active-edit-button' : 'inactive-edit-button'},[_c('v-spacer'),_c('v-btn',{attrs:{"fab":"","x-small":"","color":"error"},on:{"click":function($event){_vm.showEditDialog = true}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1):_vm._e(),(_vm.active)?_c('v-card',{staticClass:"landing-card",class:_vm.whiteFont ? 'white--text' : 'black--text',attrs:{"color":_vm.cardColor}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.cardTitle)+" ")]),_c('v-card-text',{class:_vm.whiteFont
          ? 'white--text default-font-size'
          : 'black--text default-font-size'},[_vm._v(_vm._s(_vm.cardText))])],1):_vm._e(),(_vm.showEditDialog === true)?_c('v-overlay',[_c('EditAlertCard',{attrs:{"alertCard":{
        active: _vm.active,
        cardTitle: _vm.cardTitle,
        cardText: _vm.cardText,
        cardColor: _vm.cardColor,
      }},on:{"closeOverlay":function($event){return _vm.closeDialog()}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }