


























import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({})
export default class LanguageSwitch extends Vue {
  public readonly langs = [
    {
      name: "English",
      flag: "UK.svg",
      language: "en",
    },
    {
      name: "Deutsch",
      flag: "DE.svg",
      language: "de",
    },
  ];

  public currentFlag: string = "";

  public mounted() {
    this.languageChange(this.$store.state.language || "de");
  }

  @Watch("$store.state.language")
  public languageChange(lang: string) {
    let path = "";
    switch (lang) {
      case "de":
        path = `DE.svg`;
        break;
      case "en":
      default:
        path = `UK.svg`;
        break;
    }
    this.currentFlag = require(`../../assets/flags/${path}`);
  }

  public changeLanguage(lang: string) {
    this.$store.commit("updateLang", lang);
  }
}
