




























































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import DatabaseService from "../../services/databaseService";
import SnackBar from "../util/SnackBar.vue";
import Rule from "../util/rules";

// TODO: fix snackbar not displaying

@Component({
  components: {
    SnackBar,
  },
})
export default class AddResult extends Vue {
  @Prop()
  resultEntry: any;

  public requiredRule = new Rule().requiredRule;

  public valid = false;

  public wonItems: any = [
    {
      label: this.$t("results.state.win"),
      value: "win",
    },
    {
      label: this.$t("results.state.lose"),
      value: "lose",
    },
    {
      label: this.$t("results.state.draw"),
      value: "draw",
    },
  ];
  public home: string = "";
  public away: string = "";
  public state: string = '';
  public link: string = "";
  public result: string = "";

  public dbService = new DatabaseService();
  public showSnackBar = false;

  public mounted() {}

  public closeDialog() {
    this.$emit("closeOverlay");
  }

  public async addResult() {
    let newEntry = {
      home: this.home,
      away: this.away,
      state: this.state,
      link: this.link,
      result: this.result,
    };
    this.dbService
      .addLatestResult(newEntry)
      .then((result: any) => {
        this.closeDialog();
      })
      .catch((err: any) => {
        alert(err);
      });
  }
}
