import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { de } from './config/de';
import { en } from './config/en';


Vue.use(VueI18n);


export const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'de',
    fallbackLocale: 'de',
    messages: {
        en,
        de,
    },
});