































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import Footer from "../Footer/Footer.vue";
import LanguageSwitch from "../util/LanguageSwitch.vue";

@Component({
  components: {
    Footer,
    LanguageSwitch,
  },
})
export default class Navigation extends Vue {
  public drawer: boolean = false;
  public routes: any[] = [
    {
      value: "/",
      name: "navigation.home",
    },
    {
      value: "/about",
      name: "navigation.aboutUs",
    },
    {
      value: "/training",
      name: "navigation.training",
    },
    {
      value: "/teams",
      name: "navigation.teams",
    },
  ];

  public mounted() {}

  public hoverId: any = null;

  public goTodetail(route: any) {
    this.$router.push({ name: route });
  }
}
