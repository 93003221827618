










































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
@Component({})
export default class AllResults extends Vue {
  @Prop()
  public results: any;
  public isMobile = false;
  public headers: any[] = [
    {
      text: this.$t("results.won"),
      value: "won",
      sortable: false,
      filterable: false,
      groupable: false,
      align: "center",
    },
    {
      text: this.$t("results.homeTeam"),
      value: "home",
      sortable: false,
      filterable: false,
      groupable: false,
      align: "center",
    },
    {
      text: "",
      value: "result",
      sortable: false,
      filterable: false,
      groupable: false,
      align: "center",
    },
    {
      text: this.$t("results.awayTeam"),
      value: "away",
      sortable: false,
      filterable: false,
      groupable: false,
      align: "center",
    },
  ];

  public async mounted() {
    window.addEventListener("resize", this.onResize);
    this.onResize();
    this.setIconColors();
  }
  /*
   * navigating to external website
   */
  public navigateTo(link: string) {
    window.location.href = link;
  }

  public onResize() {
    if (window.innerWidth < 769) this.isMobile = true;
    else this.isMobile = false;
  }

  public setIconColors() {
    this.results.forEach((element: any) => {
      switch (element.state) {
        case "win":
          element.color = "success";
          break;
        case "lose":
          element.color = "error";
          break;
        case "draw":
          element.color = "warning";
          break;
        default:
          element.color = "black";
          break;
      }
    });
  }

  public getActiveClass(state: any) {
    let activeClass = "";
    switch (state) {
      case "win":
        activeClass = "won-class";
        break;
      case "lose":
        activeClass = "lost-class";
        break;
      case "draw":
        activeClass = "draw-class";
        break;
    }
    return activeClass;
  }
}
