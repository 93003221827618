














import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import DatabaseService from "../../services/databaseService";
import AllResults from "./AllResults.vue";

@Component({
  components: {
    AllResults,
  },
})
export default class ShowAllResults extends Vue {
  public dbService = new DatabaseService();
  public results = [];

  public async mounted() {
    this.results = await this.dbService.getLatestResults("1000");
  }

  public closeOverlay() {
    this.$emit("closeOverlay");
  }
}
