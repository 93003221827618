// German
export const de = {
    general: {
        learnMore: 'Mehr erfahren',
        close: 'Schließen',
        required: 'Dieses Angabe ist Pflicht',
        reLogin: 'Es sieht so aus, wie als wäre dein Login abgelaufen. Bitte logge dich erneut ein.',
        seeAll: 'Alle Ergebnisse sehen',
        networkError: 'Der Server war nicht erreichbar - einige Daten werden nicht richtig geladen!',
        noContent: 'Hier scheint es noch nichts zu geben',
        contact: 'Oder kontaktiere uns einfach direkt',
        areYouSure: 'Bist du dir sicher? Diese Aktion kann nicht rückgängig gemacht werden!'
    },
    about: {
        firstHeader: 'Über uns',
        subtitle: 'Wir stellen uns vor',
        firstChapter: 'Der BV 57 Niedersedlitz e.V. ist ein reiner Badminton-Verein in der sächsischen Landeshauptstadt Dresden. Angesiedelt ist er im Osten der Stadt. Als einer der ältesten Badmintonvereine in Sachsen wurde der BV 57 Niedersedlitz als Sektion Federball der BSG Chemie Niedersedlitz 1957 gegründet. Damals war der Badmintonsport in Deutschland gerade den Kinderschuhen entwachsen. Heute ist er als einer der drei "weißen" Sportarten, aufgrund der Tradition, weiße Sportsachen zu tragen, neben Tennis und Squash aus dem Breitensport nicht mehr wegzudenken. Das dem auch in Dresden so ist, daran haben auch langjährige Mitglieder und Trainer des BV 57 Niedersedlitz ihren Anteil.',
        secondChapter: 'Sie ließen sich in ihrer Freude am Federball nicht beirren und erlebten zu DDR- Zeiten alle Höhen und Tiefen dieser wenig bekannten und zumeist belächelten Sportart. Trainer und Sportler mit einer professionellen Federballausbildung waren nicht vorhanden. Die zur Verfügung stehende alte 2 Feld-Halle entsprach keineswegs den Anforderungen einer wettkampfgerechten Anlage. Erst ab der Saison 1981/82 ,mit einer neuen 5-Feld-Halle, konnte dies zugunsten der Sportlerinnen und Sportler gewandelt werden. Dabei wurde wiederum der Federballsport als Stiefkind des DDR-Leistungssports behandelt, denn es wurden nicht alle Felder wettkampfgerecht markiert. Aber unsere Mitglieder ließen sich in ihrer Freude am Federball nicht beirren und leisteten über viele Jahre im Stillen eine gute Arbeit.',
        thirdChapter: 'Durch beachtenswerte Erfolge konnte die damalige Sektion als Vertreter des Federballsportes einen guten Namen erringen. Dies resultierte nicht nur aus dem erfolgreichen Aufbau des Kinder- und Jugendsports in den 70er Jahren innerhalb des Clubs. Einige der damaligen „Anfänger“ sind auch heute noch für unsere Verein aktiv. 1985 schafften wir sogar den Aufstieg in die DDR-Liga und konnten diesen Erfolg 1988 wiederholen.',
        fourthChapter: 'Nach Neugründung als BV 57 Niedersedlitz e.V. im November 1990 errang die erste Mannschaft 1991 den Titel des Landesmeisters Sachsen und spielt seitdem in den oberen Ligen. In der Spielsaison 1994/95, als wir den 3. Platz erkämpften, verzichteten die vor uns liegenden Mannschaften auf den Aufstieg. So wäre für uns der Weg in die Regionalliga frei gewesen. Doch als reinem Volkssportverein fehlten uns hierzu die finanziellen Mittel. Für die Finanzierung des normalen Spielbetriebes reichten die Mitgliedsbeiträge der derzeit ca. 80 Mitglieder aus. Für die Regionalliga bedurfte es eines finanzkräftigen Sponsors, der die Mehraufwendungen der ersten Mannschaft getragen hätte.',
        secondHeader: 'Neugründung und Erfolge',
        fifthChapter: 'Mit derzeit rund 90 Mitgliedern gehört der BV 57 Niedersedlitz zu einen der zahlenmäßig stärksten Badminton-Vereine bzw. Abteilungen in Sachsen. Aber nicht nur zahlenmäßig kann sich unser Verein sehen lassen. Auch auf beachtliche Leistungen und Erfolge kann unser Verein verweisen. Der BV 57 Niedersedlitz nimmt derzeit mit 1 Jugend- und 4 Aktivenmannschaften am Wettkampfgeschehen in ganz Sachsen und auch darüber hinaus rege teil. Weiterhin pflegten wir Freundschaftsvergleiche mit tschechischen und polnischen Badmintonclubs; diese Tradition wurde im vereinigten Deutschland mit westdeutschen Vereinen aus Gaimersheim, Ingolstadt und Herxheim fortgesetzt. Doch nicht nur die mannschaftlichen Leistungen sprechen für sich.',
        sixthChapter: 'Besonders erwähnenswert sind die grandiosen Erfolge unserer Sportfreunde Kerstin Radtke, Uwe Schöps und Sigwart Geihsler in den letzten Jahren. Sie errangen viele Sachsen-, Süddeutsche- und Deutschemeistertitel. Volker Hempel spielte seit über 10 Jahren Badminton in einem anderen Traditionsverein, wechselten dann aber wegen besserer sportlicher Perspektiven zum BV 57 Niedersedlitz, einem der derzeit stärksten Dresdner Badmintonclubs. Volker Hempel war 7-facher DDR-Meister in Einzel- und Doppeldisziplinen.',
    },
    footer: {
        headline: 'Impressum',
        instaLink: 'Besuche uns auf Instagram',
        impressumLink: '2020, Impressum BV57 Niedersedlitz',
    },
    imprint: {
        headline: 'Impressum',
        subtitle: 'Informationen, Dokumente und mehr',
        represented: 'Der BV 57 Niedersedlitz e.V. wird vertreten durch',
        club: 'BV57 Niedersedlitz',
        address: 'Postfach 53 01 09',
        postalCode: '01291 Dresden',
        telephone: 'Tel.: 0351 / 27568827',
        number: 'Unser Verein ist unter folgenden Nummern',
        registered: 'registriert',
        bankInfo: 'Unsere Bankverbindung lautet',
        bank: 'Ostsächsische Sparkasse Dresden',
        IBAN: 'IBAN: DE14 8505 0300 3120 0588 81',
        BIC: 'BIC: OSDD DE81 XXX',
        linkInfo: 'Information zur Verlinkung anderer Seiten',
        legalInfo: 'Wir haben auf unserer Homepage Links zu anderen Seiten im Internet hinterlegt. Für alle diese Links gilt, dass wir keinerlei Einfluss auf die Gestaltung und die Inhalte der gelinkten Seiten haben. Deshalb distanzieren wir uns hiermit ausdrücklich von allen Inhalten aller Verlinkungen auf dieser gesamten Webseite inkl. aller Unterseiten. Diese Erklärung gilt für alle auf dieser Homepage ausgebrachten Links und für alle Inhalte der Seiten, zu denen Links oder Banner führen.',
    },
    landing: {
        headline: 'BV57 Niedersedlitz',
        subtitle: 'Willkommen auf unserer Website!',
    },
    downloads: {
        request: 'Mitgliedsantrag stellen',
        silentRequest: 'Ruhende Mitgliedschaft beantragen',
        sepa: 'SEPA Lastschriftmandat für Vereinsbeitrag',
        beitragordnung: 'Beitragsordnung',
        ord_ehren: 'Ehrenordnung',
        ord_finanzen: 'Finanzordnung',
        ord_business: 'Geschäftsordnung',
        ord_jugend: 'Jugendordung',
        ord_recht: 'Rechtsordnung',
        satzung: 'Vereinssatzung',
        spesen: 'Spesenordnung',
        mitglied_change: 'Mitgliedsdaten ändern',
        leave: 'Austritt beantragen',
        kiju: 'Kinder- und Jugendabrechnung',
        reisekosten: 'Reisekosten abrechnen',
        bvs: 'Badmintonverband Sachsen',
        all: 'Alle Downloads',
        brandschutz: 'Brandschutzordung',
        hallenordnung: 'Margon Arena Hallenordnung',
        margon_unterweisung: 'Unterweisung Margon Arena',
    },
    joinUs: {
        join: 'Mitmachen!',
        family: 'Werde Teil unseres Teams!',
        extra: 'Du wolltest schon immer mal Badminton spielen? Dann komm\' einfach bei uns für ein Probetraining vorbei.',
        detailText: 'Wenn du Interesse hast, kontaktiere uns einfach: ',
        moreInfo: 'Trainingsgruppen, Zeiten und Ort'
    },
    links: {
        usefulLinks: 'Nützliche Links',
        allDownloads: 'Alle Downloads',
        entry: 'Eintritt',
        leave: 'Austritt',
        order: 'Ordnungen',
        members: 'Für Mitglieder',
    },
    navigation: {
        home: 'Home',
        aboutUs: 'Über uns',
        training:'Training',
        teams: 'Mannschaften'
    },
    teams: {
        headline: 'Mannschaften',
        subtitle: 'Alle unsere Teams und Ergebnisse',
        ourTeams: 'Unsere Teams',
        kids: 'Kinder und Jugend',
        youth: 'Jugendmannschaft',
        youthText: 'Unsere Jugendmannschaft',
        grown: 'Erwachsene',
        firstTeam: '1. Mannschaft',
        secondTeam: '2. Mannschaft',
        thirdTeam: '3. Mannschaft',
        fourthTeam: '4. Mannschaft',
        firstTeamSubtitle: 'Erwachsene in der Bezirksliga',
        secondTeamSubtitle: 'Erwachsene in der 1. Bezirksklasse',
        thirdTeamSubtitle: 'Erwachsene in der 2. Bezirksklasse',
        fourthTeamSubtitle: 'Erwachsene in der Kreisklasse',
        male: 'Herren',
        female: 'Damen',
    },
    contact: {
        contact: 'Ansprechpartner',
        kids:  'Kinder und Jugend',
        kidsContact: 'Nico Dannowski',
        grown: 'Erwachsene',
        grownContact: 'David Mäser',
    },
    login: {
        action: 'Login',
        username: 'Nutzername',
        email: 'E-Mail',
        password: 'Passwort',
        cancel: 'Abbrechen',
        successMessage: 'Login war erfolgreich!',
        errorMessage: 'E-Mail oder Passwort falsch!',
        logout: 'Logout',
        dialogTitle: 'Login',
    },
    ausfall: {
        trainignsausfalle: 'Trainingsausfälle',
        action: 'Hinzufügen',
        dialogTitle: 'Ausfall hinzufügen',
        date: 'Datum',
        reason: 'Grund',
        partly: 'Freie Felder',
        successMessage: 'Traingsausfall erfolgreich hinzugefügt',
        delete: 'Löschen',
        cancel: 'Abbrechen',
        deleteDialogTitle: 'Ausfall löschen',
    },
    results: {
        latestResults: 'Letzte Ergebnisse',
        allResults: 'Alle Ergebnisse',
        won: 'G/V/U',
        homeTeam: 'Heim',
        awayTeam: 'Auswärts',
        result: 'Ergebnis',
        dialogTitle: 'Spiel editieren',
        action: 'Spiel aktualisieren',
        wonOrNot: 'Ergebnis',
        linkHint: 'Link zum Spiel (turnier.de)',
        addTitle: 'Spiel hinzufügen',
        state: {
            win: 'Gewonnen',
            lose: 'Verloren',
            draw: 'Unentschieden',
        }
    },
    alertCard: {
        dialogTitle: 'Karte aktualisieren',
        title: 'Title',
        text: 'Text',
        color: 'Farbe',
        active: 'Aktiv',
        action: 'Karte aktualisieren',
    },
    contactDialog: {
        title: 'Kontakte',
        head: 'Vorstand',
        substitute: 'Stellvertreter',
        treasurer: 'Kassenwart',
        mail: 'E-Mail-Adresse',
        trainer: 'Kinder- / Jugendtrainer',
    },
    training: {
        clock: 'Uhr',
        tuesday: 'Dienstag',
        wednesday: 'Mittwoch',
        friday: 'Freitag',
        kidsCompetitive: 'Kinder wettkampforientiert / Kindermannschaft (8-12 Jahre)',
        kidsHobby: 'Kinder Hobby / Kindermannschaft (8-12 Jahre)',
        youth: 'Jugend alle / Jugendmannschaft (13-18 Jahre)',
        grown: 'Erwachsene',
        grownTraining: 'Training Erwachsene (kein freies Spiel!)',
        trainingLocation: 'Wir trainieren in der Margon Arena in Dresden Seidnitz. Wie ihr dort hinkommt, seht ihr auf der kleinen Karte. Falls diese nicht korrekt dargestellt wird, hier die genaue Adresse:',
        times: 'Trainingszeiten',
        subtitle: 'Alle Infos rund ums Training',
        title: 'Training',
    }
};