





import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
@Component
export default class Skeleton extends Vue {
  @Prop({ default: 100 })
  maxWidth: number;

  @Prop({ default: 80 })
  minWidth: number;

  @Prop({ default: "1em" })
  height: string;

  @Prop({ default: "100%" })
  width: number;

  public mounted() {
    this.computedWidth();
  }

  public computedWidth() {
    return (
      this.width ||
      `${Math.floor(
        Math.random() * (this.maxWidth - this.minWidth) + this.minWidth
      )}%`
    );
  }
}
