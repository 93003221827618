import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';
import HamburgerIcon from '../assets/icons/HamburgerIcon.vue';

Vue.use(Vuetify, {
  options: {
    customProperties: true
  }
})

export default new Vuetify({
    theme: {
        dark: false,
        options: { customProperties: true },
        themes: {
          light: {
            primary: "#2980b9",
            secondary: "#1976D2",
            accent: "#f7b680",
            error: "#FF5722",
            lightText: "#ecf0f1",
            warning: "#f1c40f",
          },
          dark: {
            primary: "#1976D2",
          },
        },
      },
      icons: {
        iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg',
        values: {
          HamburgerIcon: {
            component: HamburgerIcon
          }
        }
      },
});
