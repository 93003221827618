import axios from "axios";

// TODO: ts doesn't support dates package - try something else!
// import { dates } from "dates";

export class DownloadService {
    
    public downLoadAntrag(antragFile: any) {
        const baseUrl = this.getBaseUrl();
        const fileName = this.getFileName(antragFile);
        axios({
            method: "get",
            url: baseUrl + "/" + fileName,
            responseType: "arraybuffer",
            // TODO: Dates
            data: 'dates'
        }).then(function (response) {
            const blob = new Blob([response.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "Antrag auf Mitgliedschaft.pdf";
            link.click();
        });
    }

    public downloadRuhendeMitgliedschaft(ruhendeMitgliedschaft: any) {
        const baseUrl = this.getBaseUrl();
        const fileName = this.getFileName(ruhendeMitgliedschaft);
        axios({
            method: "get",
            url: baseUrl + "/" + fileName,
            responseType: "arraybuffer",
            // TODO: Dates
            data: 'dates'
        }).then(function (response) {
            const blob = new Blob([response.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "Antrag auf ruhende Mitgliedschaft.pdf";
            link.click();
        });
    }

    public downloadSepaMandat(sepaMandat: any) {
        const baseUrl = this.getBaseUrl();
        const fileName = this.getFileName(sepaMandat);
        axios({
            method: "get",
            url: baseUrl + "/" + fileName,
            responseType: "arraybuffer",
            // TODO: Dates
            data: 'dates'
        }).then(function (response) {
            const blob = new Blob([response.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = "SEPA Lastschriftmandat BV57.pdf";
            link.click();
        });
    }

    public getBaseUrl() {
        let re = new RegExp(/^.*\//);
        const rootUrl = re.exec(window.location.href);
        re = new RegExp(/(.*?)\/#/);

        if (rootUrl === null) {
            console.log("cannot get rootUrl");
            return;
        }

        const baseUrl = re.exec(rootUrl[0]);

        if (baseUrl === null) {
            console.log("cannot get baseurl");
            return;
        }

        // Is this working ALL the time?
        const result = baseUrl[1];
        return result;
    }

    public downloadFile(file: any, alias:any) {
        const baseUrl = this.getBaseUrl();
        const fileName = this.getFileName(file);
        console.log(fileName);
        
        axios({
            method: "get",
            url: baseUrl + "/" + fileName,
            responseType: "arraybuffer",
            // TODO: Dates
            data: 'dates'
        }).then(function (response) {
            const blob = new Blob([response.data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = alias +".pdf";
            link.click();
        });
    }

    public getFileName(fileName: any): any {
        const re = new RegExp(/([a-zA-z]*[0-9]*)*.pdf/);
        const filteredFileName = re.exec(fileName);
        // Is this working ALL the time?
        if (!filteredFileName) {
            console.log("cannot get rootUrl");
            return;
        }
        const result = filteredFileName[0];
        return result;
    }
}