





















































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Headline from "../util/Headline.vue";
import Contact from "../util/Contact.vue";

// leaflet related imports
import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet";
import { latLng } from "leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";

@Component({
    components: {
        Headline,
        Contact,
        LMap,
        LTileLayer,
        LMarker,
        LTooltip
    }
})
export default class Training extends Vue {
    public trainings: any[] = [
        {
            day: "training.tuesday",
            times: [
                {
                    slot: "17:00-18:30",
                    team: "training.kidsCompetitive"
                },
                {
                    slot: "18:30-20:00",
                    team: "training.youth"
                },
                {
                    slot: "20:00-22:30",
                    team: "training.grown"
                }
            ]
        },
        {
            day: "training.wednesday",
            times: [
                {
                    slot: "17:30-18:30",
                    team: "training.kidsHobby"
                },
                {
                    slot: "18:30-20:00",
                    team: "training.grownTraining"
                },
                {
                    slot: "20:00-22:30",
                    team: "training.grown"
                }
            ]
        },
        {
            day: "training.friday",
            times: [
                {
                    slot: "17:00-18:30",
                    team: "training.kidsCompetitive"
                },
                {
                    slot: "18:30-20:00",
                    team: "training.youth"
                },
                {
                    slot: "20:00-22:30",
                    team: "training.grown"
                }
            ]
        }
    ];

    public mounted() {
        // css magic for leaflet to show the marker icon
        // Fix for wrong marker icon related to: https://github.com/Leaflet/Leaflet/issues/4968#issuecomment-399857656
        let prototype = L.Icon.Default.prototype as any;
        delete prototype._getIconUrl;

        L.Icon.Default.mergeOptions({
            iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
            iconUrl: require("leaflet/dist/images/marker-icon.png"),
            shadowUrl: require("leaflet/dist/images/marker-shadow.png")
        });
    }
    public toggleContact: boolean = true;

    // leaflet configuration
    public zoom: number = 17;
    public center: any = latLng(51.0226, 13.8086);

    // check leaflet providers for different options
    public url: string = "http://{s}.tile.osm.org/{z}/{x}/{y}.png";
    public attribution: string =
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors';
    public withTooltip: any = [51.0226, 13.8086];
    public currentZoom: number = 11.5;
    public currentCenter: any = latLng(51.0226, 13.8086);
    public showParagraph: boolean = false;
    public mapOptions: any = {
        zoomSnap: 0.5
    };

    // leaflet related functions
    public zoomUpdate(zoom: any) {
        this.currentZoom = zoom;
    }

    public centerUpdate(center: any) {
        this.currentCenter = center;
    }
}
