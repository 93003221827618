















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import Downloads from "../../util/Downloads.vue";

@Component({
  components: {
    Downloads,
  },
})
export default class Links extends Vue {
  @Prop()
  public cardWidth: string;

  @Prop()
  public cardHeight: number;
}
