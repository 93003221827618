











import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import DatabaseService from "../../services/databaseService";

@Component({})
export default class SnackBar extends Vue {
  @Prop()
  message: string;

  public snackbar: boolean = true;

  public closeSnackBar() {
      this.snackbar = false;
      this.$emit('closeSnackBar');
  }
}
