









































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Headline from "../util/Headline.vue";
import Team from "../util/Team.vue";
import DatabaseService from "../../services/databaseService";

@Component({
  components: {
    Headline,
    Team,
  },
})
export default class About extends Vue {
  public dbService = new DatabaseService();

  public teams: any = [
    {
      title: "teams.firstTeam",
      subtitle: "teams.firstTeamSubtitle",
      link: "https://www.turnier.de/sport/league/team?id=93B5D39E-6341-4CC4-A6D9-C67CC0D5FC47&team=118",
      teamShortcut: "1",
      players: {
        male: [],
        female: [],
      },
      teamName: "one",
    },
    {
      title: "teams.secondTeam",
      subtitle: "teams.secondTeamSubtitle",
      link: "https://www.turnier.de/sport/league/team?id=93B5D39E-6341-4CC4-A6D9-C67CC0D5FC47&team=125",
      teamShortcut: "2",
      players: {
        male: [],
        female: [],
      },
      teamName: "two",
    },
    {
      title: "teams.thirdTeam",
      subtitle: "teams.thirdTeamSubtitle",
      link: "https://www.turnier.de/sport/league/team?id=93B5D39E-6341-4CC4-A6D9-C67CC0D5FC47&team=135",
      teamShortcut: "3",
      players: {
        male: [],
        female: [],
      },
      teamName: "three",
    },
    // {
    //   title: "teams.fourthTeam",
    //   subtitle: "teams.fourthTeamSubtitle",
    //   link: "https://www.turnier.de/sport/league/team?id=93B5D39E-6341-4CC4-A6D9-C67CC0D5FC47&team=145",
    //   teamShortcut: "4",
    //   players: {
    //     male: [],
    //     female: [],
    //   },
    //   teamName: "four",
    // },
    {
      title: "teams.youth",
      subtitle: "teams.youthText",
      link: "https://www.turnier.de/sport/league/team?id=93B5D39E-6341-4CC4-A6D9-C67CC0D5FC47&team=125",
      teamShortcut: "J",
      players: {
        male: [],
        female: [],
      },
      teamName: "youth",
    },
  ];

  public async mounted() {
    this.setPlayers();
  }

  public async setPlayers() {
    for (let team of this.teams) {
      team.players = await this.dbService.getTeam(team.teamName);
    }
  }
}
