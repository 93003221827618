import axios from 'axios';
import { store } from '../plugins/vuex/store';
import router from '../router/router';

/** Config object to set BaseUrl and timeout */
const config = {
    // dev deployment
    // baseURL: 'https://www.bv-admin.de',

    // local deployment
    // baseURL: 'http://localhost:8135',

    // production deployment
    baseURL: 'https://www.bv57nsdl.de',
    timeout: 50000,
};


let http: any = {};

http = axios.create(config);

// Request interceptor
http.interceptors.request.use(
    (config: any) => {
        config.headers.Authorization = `Bearer ${store.getters.accessToken}`;
        return config;
    },
    (error: any) => {
        router.push('/');
        return Promise.reject(error);
    },
);

// Response interceptor
http.interceptors.response.use(
    (response: any) => {
        return response.data;
    },
    (error: any) => {
        return Promise.reject(error);
    },
);

export default http;
