

























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import DatabaseService from "../../services/databaseService";
import SnackBar from "../util/SnackBar.vue";

@Component({
  components: {
    SnackBar,
  },
})
export default class EditAusfall extends Vue {
  @Prop()
  ausfallEntry: any;

  public dbService = new DatabaseService();

  public mounted() {}

  public closeDialog() {
    this.$emit("closeOverlay");
  }

  public async deleteAusfall() {
    this.dbService
      .deleteAusfall(this.ausfallEntry.id)
      .then((result: any) => {
        this.closeDialog();
      })
      .catch((err: any) => {
        alert(err);
      });
  }
}
