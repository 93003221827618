import Vue from 'vue';
import Router from 'vue-router';
import About from '../components/About/About.vue';
import Landing from '@/components/Landing/Landing.vue';
import Training from "@/components/Training/Training.vue";
import Teams from "@/components/Teams/Teams.vue";
import Impressum from "@/components/Impressum/Impressum.vue"


Vue.use(Router)

export default new Router({
    routes: [
        {
            path: '/',
            name: 'landing',
            component: Landing
        },
        {
            path: '/about',
            name: 'about',
            component: About
        },
        {
            path: '/training',
            name: 'training',
            component: Training
        },
        {
            path: '/teams',
            name: 'teams',
            component: Teams
        },
        {
            path: '/impressum',
            name: 'impressum',
            component: Impressum
        },
        { path: "*", component: Landing }

    ]
})