




















































import Vue from "vue";
import { Component } from "vue-property-decorator";
import Headline from "../util/Headline.vue";

@Component({
  components: {
    Headline,
  },
})
export default class Impressum extends Vue {
  public numbers: any = [
    {
      number: "410023",
      text: "beim Landessportbund Sachsen (LSBS)",
    },
    {
      number: "319",
      text: "beim Badmintonverband Sachsen (BVS)",
    },
    {
      number: "I / 756",
      text: "beim Vereinsregister",
    },
  ];
}
