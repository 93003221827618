

































import Vue from "vue";
import { Component } from "vue-property-decorator";
import DatabaseService from "../../services/databaseService";
import SnackBar from "../util/SnackBar.vue";

@Component({
  components: {
    SnackBar,
  },
})
export default class LoginDialog extends Vue {
  public password: string = "";
  public userName: string = "";

  public dbService = new DatabaseService();
  public showSnackBar = false;

  public login() {
    this.dbService
      .login(this.userName, this.password)
      .then((result: any) => {
        localStorage.setItem("accessToken", result.accessToken);
        this.$store.commit("updateAccessToken", result.accessToken);
        this.showSnackBar = true;
        this.closeDialog();
      })
      .catch((err: any) => {
        alert(this.$t("login.errorMessage"));
      });
  }

  public closeDialog() {
    this.$emit("closeOverlay");
  }

  public logout() {
    this.dbService.logout();
  }
}
